import React, { useState, useEffect } from "react";
import styles from "./ItemCard.module.scss";
import { IoHeartOutline, IoHeartSharp } from "react-icons/io5";
import { BiCartDownload } from "react-icons/bi";
import { AiTwotoneEdit, AiOutlineDelete } from "react-icons/ai";
import { MdBookmarkAdded } from "react-icons/md";

const ItemCard = ({
  index,
  contentItem,
  onEdit,
  onDelete,
  onAddToCart,
  onRemoveFromCart,
  cartItems,
}) => {
  const [isLike, setIsLike] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const isReverseContent = index % 4 === 2 || index % 4 === 3;
  const notFoundImagePath =
    process.env.PUBLIC_URL + "/assets/images/notFoundImage.png";

  const [imageSrc, setImageSrc] = useState(
    contentItem.image ? contentItem.image : notFoundImagePath
  );
  useEffect(() => {
    const isInCart = cartItems.some(
      (cartItem) => cartItem.id === contentItem.id
    );
    setIsAddedToCart(isInCart);
  }, [cartItems, contentItem.id]);

  const handleLike = () => {
    setIsLike(!isLike);
  };

  const handleCartClick = () => {
    if (isAddedToCart) {
      onRemoveFromCart();
    } else {
      onAddToCart();
    }
    setIsAddedToCart(!isAddedToCart);
  };

  const handleError = () => {
    setImageSrc(notFoundImagePath);
  };
  return (
    <li
      className={`${styles.contentItem} ${
        isReverseContent ? styles.reverseContent : ""
      } ${isAddedToCart ? styles.addedToCart : ""}`}
    >
      <div className={styles.itemImage}>
        <img src={imageSrc} alt={contentItem.name} onError={handleError} />
      </div>
      <div className={styles.description}>
        <h4>{contentItem.name}</h4>
        <p>{contentItem.details}</p>
        <div className={styles.rowPrice}>
          <span className={styles.price}>
            {contentItem.price}
            <span>تومان</span>{" "}
          </span>
          {contentItem.price_large && contentItem.price_large ? (
            <>
              {" "}
              <span className={styles.price}>
                {contentItem.price_large}
                <span className={styles.pricedb}>
                  <p>تومان</p>
                  <p>دو نفره</p>
                </span>
              </span>
            </>
          ) : (
            ""
          )}
        </div>
        <div className={styles.panel}>
          <span onClick={handleCartClick}>
            {isAddedToCart ?" حذف از سبد": "افزودن"}
          </span>
        </div>
      </div>
    </li>
  );
};

export default ItemCard;
