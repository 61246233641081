import React, { useEffect, useState } from "react";
import styles from "./Menu.module.scss";
import ItemCard from "../../components/ItemCard/ItemCard";
import { RiLoaderFill } from "react-icons/ri";
import { MdLibraryAdd, MdOutlineManageSearch } from "react-icons/md";
import { useOutletContext } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { data } from "../data/data";

const Menu = () => {
  const [selectedGroup, setSelectedGroup] = useState(1);
  const [menuItems, setMenuItems] = useState([]);
  const [menuTitles, setMenuTitles] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [isLockScreen, setIsLockScreen] = useState(false);
  const [addTitle, setAddTitle] = useState("");
  const [addDesc, setAddDesc] = useState("");
  const [addPrice, setAddPrice] = useState("");
  const [forceRender, setForceRender] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [imageUrl, setImageUrl] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const { cartItems, handleAddToCart, handleRemoveFromCart } = useOutletContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchAllGroups, setSearchAllGroups] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoad(true);
      try {
        const menuItems = data.items;
        const menuTitles = data.groups;
        setMenuItems(menuItems);
        setMenuTitles(menuTitles);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoad(false);
      }
    };
    fetchData();
  }, [forceRender]);

  const handleClick = (index) => {
    setSelectedGroup(index + 1);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term) {
      const filtered = menuItems.filter((item) =>
        item.name.toLowerCase().includes(term.toLowerCase())
      );
      if (searchAllGroups) {
        setFilteredItems(filtered);
      } else {
        setFilteredItems(filtered.filter((item) => item.group_id === selectedGroup));
      }
    } else {
      setFilteredItems([]);
    }
  };

  const toggleSearchInput = () => {
    if (showSearchInput) {

      setSearchTerm("");
      setFilteredItems([]);
      setSearchAllGroups(false); 
    }
    setShowSearchInput(!showSearchInput);
  };

  const handleCheckboxChange = () => {
    setSearchAllGroups(!searchAllGroups);
    setFilteredItems([]);
    setSearchTerm("");
  };

  return (
    <div>
      <div
        className={styles.container}
        style={isLockScreen ? { overflowY: "hidden" } : {}}
      >
        <div className={styles.searchFood}>
          <MdOutlineManageSearch onClick={toggleSearchInput} />
          {showSearchInput && (
            <div className={styles.searchContainer}>
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="اسم غذایی که میخوای ..."
                className={styles.searchInput}
              />
              <label>
                <input
                  type="checkbox"
                  checked={searchAllGroups}
                  onChange={handleCheckboxChange}
                />
                جستجو در تمام لیست
              </label>
            </div>
          )}
        </div>
        <h3 className={styles.menuTitle}>منو</h3>
        {isLoad ? (
          <RiLoaderFill className="m-auto animate-spin text-5xl text-center w-full" />
        ) : (
          <>
            <ul className={styles.menuList}>
              {menuTitles.map((item, index) => (
                <li
                  key={item.id}
                  className={`${styles.menuItem} ${
                    selectedGroup === index + 1 ? styles.selected : ""
                  }`}
                  onClick={() => handleClick(index)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            <div className={styles.content}>
              <ul>
                {(searchTerm ? filteredItems : menuItems)
                  .filter((contentItem) => searchAllGroups || contentItem.group_id === selectedGroup)
                  .map((contentItem, index) => (
                    <ItemCard
                      index={index}
                      key={contentItem.id}
                      contentItem={contentItem}
                      onDelete={""}
                      onEdit={""}
                      onAddToCart={() => handleAddToCart(contentItem)}
                      onRemoveFromCart={() => handleRemoveFromCart(contentItem)}
                      cartItems={cartItems}
                    />
                  ))}
              </ul>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Menu;